import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStore } from '@ngrx/store';

import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideEffects } from '@ngrx/effects';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { environment } from 'environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideClientHydration(),
    provideAnimations(),
    provideStore(),
    provideHttpClient(withFetch()),
    provideStoreDevtools({
      name: 'Mercado-Meet',
      maxAge: 25,
      autoPause: true,
      trace: true,
      traceLimit: 75,
      logOnly: !environment.production,
      connectInZone: true
    }),
    provideEffects([]),
  ],
};
